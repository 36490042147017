import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "main pages" }
const _hoisted_2 = { class: "page-content pt-100 pb-100" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-lg-10 m-auto" }
const _hoisted_6 = { class: "row d-block" }
const _hoisted_7 = { class: "col-md-3 float-start" }
const _hoisted_8 = { class: "col-md-9 float-start positionRelative account" }
const _hoisted_9 = { class: "card" }
const _hoisted_10 = { class: "card-header" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-lg-12" }
const _hoisted_13 = { class: "mb-0" }
const _hoisted_14 = { class: "card-body form-group" }
const _hoisted_15 = {
  key: 0,
  class: "row"
}
const _hoisted_16 = { class: "wishListProduct" }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = ["href", "onClick"]
const _hoisted_19 = ["href", "onClick"]
const _hoisted_20 = { class: "main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainHeader = _resolveComponent("MainHeader")!
  const _component_AccountNavComponent = _resolveComponent("AccountNavComponent")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_MainFooter = _resolveComponent("MainFooter")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_MainHeader),
          _createElementVNode("main", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_AccountNavComponent)
                      ]),
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("div", _hoisted_11, [
                              _createElementVNode("div", _hoisted_12, [
                                _createElementVNode("h3", _hoisted_13, _toDisplayString(_ctx.t("account.wishList")), 1)
                              ])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_14, [
                            (_ctx.wishlistItems.length)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.wishlistItems, (wishlistItem, itemIndex) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      class: "col-md-4",
                                      key: itemIndex
                                    }, [
                                      _createElementVNode("div", _hoisted_16, [
                                        _createElementVNode("a", {
                                          class: "removeItem",
                                          onClick: ($event: any) => (
                                  _ctx.removeWishlistItem(
                                    wishlistItem.wishlist_item_id,
                                    $event
                                  )
                                )
                                        }, [
                                          _createVNode(_component_ion_icon, {
                                            icon: _ctx.icons.trashOutline,
                                            color: "danger"
                                          }, null, 8, ["icon"])
                                        ], 8, _hoisted_17),
                                        _createElementVNode("a", {
                                          href: _ctx.getProductUrl(wishlistItem.product),
                                          onClick: ($event: any) => (
                                  _ctx.nav(
                                    _ctx.getProductUrl(wishlistItem.product),
                                    $event
                                  )
                                )
                                        }, [
                                          _createVNode(_component_ion_img, {
                                            src: _ctx.getImageUrl(wishlistItem.product)
                                          }, null, 8, ["src"])
                                        ], 8, _hoisted_18),
                                        _createElementVNode("div", {
                                          class: "title",
                                          href: _ctx.getProductUrl(wishlistItem.product),
                                          onClick: ($event: any) => (
                                  _ctx.nav(
                                    _ctx.getProductUrl(wishlistItem.product),
                                    $event
                                  )
                                )
                                        }, [
                                          _createVNode(_component_ion_text, {
                                            textContent: _toDisplayString(wishlistItem.product.name)
                                          }, null, 8, ["textContent"])
                                        ], 8, _hoisted_19)
                                      ])
                                    ]))
                                  }), 128))
                                ]))
                              : _createCommentVNode("", true)
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("footer", _hoisted_20, [
            _createVNode(_component_MainFooter)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}