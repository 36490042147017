
import { IonPage, IonContent, IonText, IonIcon, IonImg } from "@ionic/vue";
import { trashOutline } from "ionicons/icons";
import { useRouter } from "vue-router";
import { defineComponent, ref, reactive, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useGlobalFunctions } from "@/libs/globalFunctions";
import settingsApp from "@/libs/settings";
import { useLocalStorage } from "@/libs/localStorage";
import httpApp from "@/libs/http";
import { useStore } from "@/store";
import AccountNavComponent from "./AccountNavComponent.vue";
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import wishlistApp from "@/libs/wishlist";

export default defineComponent({
  name: "Wishlist",
  setup() {
    const { t, tm } = useI18n();
    const { getLocalStorageData } = useLocalStorage();
    const { createLoading } = useGlobalFunctions();
    const storeX = useStore();
    const router = useRouter();
    const icons = reactive({
      trashOutline,
    });

    const wishlistItems = ref([]);

    const getAllWishlist = () => {
      wishlistItems.value = [];
      wishlistApp
        .getWishlistItems()
        .then((resp: any) => {
          if (Object.prototype.hasOwnProperty.call(resp, "items")) {
            wishlistItems.value = resp.items;
          } else {
            wishlistItems.value = [];
          }
        })
        .catch((error) => {
          wishlistItems.value = [];
          console.log(error);
        });
      // httpApp
      //   .sendGetRequest(settingsApp.getEndpointUrl("getWishlistItems"))
      //   .then((response) => {
      //     const resp = response.data;
      //     if (!Object.prototype.hasOwnProperty.call(resp, "error")) {
      //       wishlistItems.value = resp.items;
      //     } else {
      //       wishlistItems.value = [];
      //     }

      //   })
      //   .catch((error) => {
      //     console.log(error, "error");
      //   });
    };

    const removeWishlistItem = async (itemId: any, event: any) => {
      event.preventDefault();
      const loading = await createLoading(t("pleaseWait"));
      loading.present();
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("deleteWishlistItem"), {
          item: itemId,
        })
        .then(async (response: any) => {
          if (response.success) {
            getAllWishlist();
            loading.dismiss();
          }
        })
        .catch((error) => {
          loading.dismiss();
          console.log(error, "error");
        });
    };

    const getImageUrl = (product) => {
      if (product.image == "no_selection") {
        return "assets/images/placeholder.png";
      } else if (product.image) {
        return (
          getLocalStorageData("websiteUrl") +
          "media/catalog/product" +
          product.image
        );
      } else if (product.thumbnail && product.thumbnail != "no_selection") {
        return (
          getLocalStorageData("websiteUrl") +
          "media/catalog/product" +
          product.thumbnail
        );
      } else if (product.small_image) {
        return (
          getLocalStorageData("websiteUrl") +
          "media/catalog/product" +
          product.small_image
        );
      } else {
        return "assets/images/placeholder.png";
      }
    };

    const getProductUrl = (product) => {
      let url = "/" + storeX.state.storeCode;
      if (product.url_path_category) {
        url += "/" + product.url_path_category;
      } else {
        url += "/" + product.url_path;
      }
      return url;
    };

    const nav = (path, event) => {
      event.preventDefault();
      router.push(path);
    };

    watch(
      () => storeX.state.storeCode,
      () => {
        getAllWishlist();
      }
    );

    function ionViewWillEnter() {
      getAllWishlist();
    }

    return {
      t,
      tm,
      storeX,
      icons,
      wishlistItems,
      ionViewWillEnter,
      removeWishlistItem,
      getImageUrl,
      getProductUrl,
      nav,
    };
  },
  components: {
    IonPage,
    IonContent,
    IonText,
    IonIcon,
    IonImg,
    AccountNavComponent,
    MainHeader,
    MainFooter,
  },
});
